.aboutEng{
    display: flex;
    justify-content: center;
    position: relative;
}

.itemsContainerEng{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 90px;
    padding: 0 15px;
    gap:30px;
}

.aboutTitleEng{
    position: absolute;
    top:15px;
    font-size: 40px;
    font-weight: 400;
    color:  #1C0C5B;

}


.aboutItemsEng .aboutItemsEngReveal{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

   
}

.aboutItemsEng>p{
    font-size: 1.0625em;
    line-height: 24px;
    color: rgb(67, 67, 67);
    line-height: 24px;
    font-weight: 400;
    direction: ltr;
    margin-bottom: 120px;
}

.aboutButtonEng{
    position: absolute;
    font-size: 15px;
    padding: 15px 40px;
    border-radius: 50px;
    border: 2px solid #916bbf;
    background-color: white;
    color: #916bbf;
    font-weight: bold;
    transform: translate(-50%,-50%);
    left:50%;
    bottom:0;
    transition-duration: 0.2s;
    cursor: pointer;
   
}


.aboutButtonEng:hover{

    background-color: #916bbf;
    color: white;
}


.videoContainerEng{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 125px;

}

.videoItemEng{
    height: 250px;
    width: 450px;
    -webkit-box-shadow: 11px 8px 11px 2px rgba(0,0,0,0.5); 
    box-shadow: 11px 8px 11px 2px #00000080;
}


.aboutTitleEng.animate__animated.animate__fadeIn{
    --animate-duration: 5s;
}

.animate__animated.animate__fadeInLeft
{
    --animate-duration: 2s;
}
.animate__animated.animate__fadeInRight
{
    --animate-duration: 2s;
}



@media screen and (max-width:1023px) {
    

    .itemsContainerEng{

        flex-direction: column-reverse;
        padding: 0 10px;
        gap:0px;


    }
    .videoContainerEng{
        width: 65%;
    }

    .videoItemEng{

        margin-bottom: 10px;

    }

    .aboutItemsEng .aboutItemsEngReveal{

        width: 65%;
    }


    
}


@media screen and (max-width:803px) {

    .aboutItemsEng .aboutItemsEngReveal{
        width: 75%;
    }

}


@media screen and (max-width:605px) {

    .videoItemEng{

        height: 240px;
        width: 426px;

    }

    .aboutItemsEng .aboutItemsEngReveal{
        width: 85%;
    }



}
@media screen and (max-width:440px) {

    .videoItemEng{

        height: 220px;
        width: 400px;

    }

    .aboutItemsEng .aboutItemsEngReveal{
        width: 90%;
    }

}

@media screen and (max-width:375px) {

    .videoItemEng{

        height: 200px;
        width: 340px;

    }

    .aboutItemsEng .aboutItemsEngReveal{
        width: 90%;
    }

    .aboutTitleEng{
        font-size: 35px;
    }
    
    .aboutItemsEng>p{
        font-size: 15px;
    }

    .aboutButtonEng{
        padding: 12px 30px;
        font-size: 13px;
        bottom:15px;
    }
}




@media screen and (max-width:320px) {

    .videoItemEng{

        height: 160px;
        width: 280px;

    }

    .aboutItemsEng .aboutItemsEngReveal{
        width: 90%;
    }

    .aboutTitleEng{
        font-size: 35px;
    }
    
    .aboutItemsEng>p{
        font-size: 15px;
    }

    .aboutButtonEng{
        padding: 12px 30px;
        font-size: 13px;
        bottom:15px;
    }
}
