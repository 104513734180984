.iso{
    display: flex;
    justify-content: center;
    position: relative;
    background-image: linear-gradient(to left, #1c0c5b, #221264, #29186d, #2f1e77, #352480, #402c88, #4a3391, #543b99, #6447a2, #7352ac, #825fb5, #916bbf);

}


.isoTitle{
    position: absolute;
    font-size: 40px;
    font-weight: 400;
    color: white;
    top:15px;

}

.isoItems{
    width:80%;
    max-width:1024px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    margin-top: 15px;
    direction: rtl;
    margin: 140px 10% 115px 10%;
}

.isoItem>span{

    font-size: 1.05em;
    font-weight: 500;
    margin-right: 15px;
    letter-spacing: 0.5px;
    font-weight: 400;


}

.isoIcon{
    font-size: 2.1em;
    color:#00a997;
    margin-right: 10px;
}



.isoItem{
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;

}

      .animate__animated.animate__bounceInLeft{
        --animate-duration: 1s;
    }
    @media screen and (max-width:480px) {

        .isoItems{
            width: 100%;
            margin: 100px 0 80px 10%;
    
        }
    
        .isoTitle{
            font-size: 35px;
        }
    
        .isoItem{
            font-size: 1.05em;
    
        }
        .isoItem>span{
            font-size: 0.9375em;
    
        }
      
    }
    @media screen and (max-width:325px) {
    
        .isoItems{
            width: 100%;
    
        }

    
    }