.navbar{
    height: 100px;
    display: flex;
    justify-content: center;
}

.navContainer{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    position: relative;
    
}

.logo{
    height: 250px;
    margin-bottom: 25px;
    position: absolute;
    left:-3%;
}

.navItems{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    cursor: pointer;
    font-size: 1.0625em;
    font-weight: 500;
    position: absolute;
    right:0;
    padding-right: 10px;
    color: rgb(67, 67, 67);

}
.navItemsHighlight{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    cursor: pointer;
    font-size: 17px;
    font-weight: bold;
    position: absolute;
    right:0;
    padding-right: 10px;
}

.navMenu{
    display: inline-block;
    position: relative;

}
.navMenu:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #860ad3;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .navMenu:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

.navButton{
    margin-left: 20px;
    border: 1px solid black;
    color: black;
    border-radius: 15px;
    padding: 15px 30px;
    cursor: pointer;
    background-color: white;

}


/* Mobile Menu*/
.hamburgerMenuIcon{
    display: none;
}
.sidebar{
    visibility: hidden;
}

@media screen and (max-width: 768px) {

    .navItems{
        display: none;
    }

    .logo{
        height: 200px;
        left:-6%;
        padding:0 15px;
    }

    .hamburgerMenuIcon{
        position: absolute;
        display: block;
        font-size: 30px;
        right: 0;
        top:25px;
        padding: 0 5px;
        color: rgb(67, 67, 67);
    }

    .exitIcon{
        position: fixed;
        font-size: 20px;
        right: 5px;
        top:15px;
        padding: 0 5px;
        z-index: 999;
        cursor: pointer;
    }

    .sidebar{
        visibility: visible;
        position: fixed;
        height:100%;
        width: 200px;
        background-color: white;
        opacity: 0.95;
        z-index: 999;
        top:0;
        right: 0;
        transition: 0.5s;
        transition-timing-function: ease-in;
    }


    .mobileNavItems{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        margin-top: 70px;
        gap: 30px;
        cursor: pointer;
        font-size: 17px;
        font-weight: 500;

    }


  }

  @media screen and (max-width: 600px) {

    .logo{

        padding:0 10px;
    }


  }

  @media screen and (max-width: 480px) {

    .logo{

        padding:0;
    }


  }



  @media screen and (min-width:769px) and (max-width:1024px){

    

    .navItems{
        font-size: 15px;
    }

    .logo{
        height: 230px;
        margin-bottom: 25px;
        padding:0 10px;

    }

    

    
  }

