.services{
    display: flex;
    justify-content: center;
    position: relative;

    
}

.servicesTitle{
    position:absolute;
    font-size: 40px;
    font-weight: 400;
    top:15px;
    color: #1C0C5B;
}




.itemWrapper{
    max-width: 1024px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10vh;
    margin-bottom: 90px;
    padding: 0 15px;


}


.servicesItems{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  

   
}



.serviceItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
    cursor: pointer;


    
}

.serviceItem:hover{
    transform: scale(1.1);
}



.sectionContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 30%;
    height: 100%;

    
}

.serviceDesc{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 30px;
    font-size: 0.9375em;
    color: rgb(67, 67, 67);
}



.serviceImg{
    height: 90px;
    margin-bottom: 20px;
    margin-right: 15px;

}


.verticalLine{
    height: 300px;
    border: 1px solid lightgray;
}



.itemText{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125em;
    font-weight: 700;
    white-space: nowrap;
    text-align: center;
    color: rgb(81, 81, 81);

}
@media screen and (max-width:767px) {

    .itemWrapper{
        flex-direction: column;
        margin-top: 125px;
        gap:60px;


    }
    .verticalLine{
        display: none;
    }


    .serviceDesc{
        margin-top: 10px;
    }

    
}

@media screen and (max-width:480px) {
    .sectionContainer{
        width: 60%;
    }
  
    
}



