.isoEng{
    display: flex;
    justify-content: center;
    position: relative;
    background-image: linear-gradient(to left, #1c0c5b, #221264, #29186d, #2f1e77, #352480, #402c88, #4a3391, #543b99, #6447a2, #7352ac, #825fb5, #916bbf);

}

.isoTitleEng{
    position: absolute;
    font-size: 40px;
    font-weight: 400;
    color: white;
    top:15px;


}
.isoItemEng>span{
    font-size: 1.025em;
    font-weight: 500;
    margin-right: 15px;
    letter-spacing: 0.5px;
    font-weight: 400;

}

.isoItemsEng{
    width:80%;
    max-width:1024px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    margin-top: 15px;
    direction: ltr;
    margin: 140px 10% 115px 10%;
}

.isoItemEng{
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
}

.isoIcon{
    font-size: 2.1em;
    color:#00a997;
    margin-right: 10px;
}


.animate__animated.animate__bounceInLeft{
    --animate-duration: 1s;
}


@media screen and (max-width:480px) {

    .isoItemsEng{
        width: 100%;
        margin: 100px 0 80px 10%;

    }

    .isoTitleEng{
        font-size: 35px;
    }

    .isoItemEng{
        font-size: 1.025em;

    }
    .isoItemEng>span{
        font-size: 0.9375em;

    }


    
}
@media screen and (max-width:325px) {

    .isoItemsEng{
        width: 100%;

    }

}




  



    