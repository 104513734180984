
.accessSidebar{
    position: fixed;
    height:100%;
    width: 300px;
    background-color: white;
    opacity: 0.95;
    z-index: 999;
    top:0;
    right: 0;
    transition: 0.3s;
    transition-timing-function: ease-in;

}
.accessTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 15px;
}

.accessMenuWrapper{
    position: fixed;
}



.accessExitIcon{
    position: fixed;
    display: block;
    font-size: 20px;
    right:0;
    top:12px;
    padding: 0 5px;
    z-index: 999;
    cursor: pointer;
    
}


.accessMenuTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    margin-top: 5px;
}

.accessItemsWrapper{
    height: 15%;
    display: inline-flex;
    flex-wrap: wrap;
    margin-top: 100px;
}

.buttonWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
    width: 105%;
    margin-top: 30px;

}

.cancelAccess{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    border-radius: 50px;
    border: 1px solid black;
    background-color: transparent;
    z-index:3;;
    cursor: pointer;
    font-size: 14px;


}

.cancelAccess:hover{
    background-color: lightgray;

}



.accessMenuItem{
    width: 150px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    
}

.accessMenuItem:hover{
    background-color: lightgray;
}

.monoIcon{
    width: 50px;
    height: 50px;
}