.photos{
    display: flex;
    justify-content: center;
    position: relative;
    background-image: linear-gradient(to bottom, #1c0c5b, #221264, #29186d, #2f1e77, #352480, #402c88, #4a3391, #543b99, #6447a2, #7352ac, #825fb5, #916bbf);
}

.photosTitle{
    font-size: 40px;
    font-weight: 400;
    top:15px;
    color:  white;
    position: absolute;

}


.photosContainer{
    width: 100%; 
    max-width: 1024px;
    justify-content: center;
    display: flex;
    position: relative;

     
}

.photosItem{
    display: flex;
    margin:130px 0 115px 0;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 11px 8px 11px 2px rgba(0,0,0,0.5); 
    box-shadow: 11px 8px 11px 2px #00000080;
   
}


.photosItemMobile{
    display: none;
}
.photosItemSmallMobile{
    display: none;
}
.photosItemTablets{
    display: none;
}


@media screen and (max-width:830px) {

    .photosItem{
        display: none;
    }

    .photosItemTablets{
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 11px 8px 11px 2px rgba(0,0,0,0.5); 
        box-shadow: 11px 8px 11px 2px #00000080;
        margin:130px 0 115px 0;
    }

    
}
@media screen and (max-width:630px) {

    .photosItemTablets{
        display: none;
    }

    .photosItemMobile{
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 11px 8px 11px 2px rgba(0,0,0,0.5); 
        box-shadow: 11px 8px 11px 2px #00000080;
        margin:130px 0 115px 0;
      

    }
    
}

@media screen and (max-width:480px) {


    .photosItemMobile{
        display: none;

    }

    .photosItemSmallMobile{
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 11px 8px 11px 2px rgba(0,0,0,0.5); 
        box-shadow: 11px 8px 11px 2px #00000080;
        margin:130px 0 100px 0;
      
    }
    
    
}

/* 

@media screen and (max-width: 480px) and (max-height:667px) {

    .photos{
        height: 70vh;
    }

    .photosTitle{
        font-size: 35px;
    }

    .photosItem{
        display: none;
    }
    .photosWrapper{
        width: 350px;
        height: 250px;

    }
    .photosItemTablets{
        display: none;
    }

    .photosItemMobie{
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 11px 8px 11px 2px rgba(0,0,0,0.5); 
        box-shadow: 11px 8px 11px 2px #00000080;
        margin-top: 45%;
    }


  }


  @media screen and (max-width: 480px) and (min-height:667px) {

    .photos{
        height: 50vh;
    }

    .photosTitle{
        font-size: 35px;
    }

    .photosItemTablets{
        display: none;
    }

    .photosItem{
        display: none;
    }
    .photosWrapper{
        width: 350px;
        height: 250px;

    }

    .photosItemMobie{
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 11px 8px 11px 2px rgba(0,0,0,0.5); 
        box-shadow: 11px 8px 11px 2px #00000080;
        margin-top: 40%;
    }


  }




  @media screen and (min-width: 768px) and (max-width:1023px){


    .photos{
        height: 50vh;
    }


    .photosItem{
        display: none;
    }

    .photosItemMobie{
     display: none;
    }

    .photosWrapper{
        width: 600px;
        height: 300px;

    }

    .photosItemTablets{
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 11px 8px 11px 2px rgba(0,0,0,0.5); 
        box-shadow: 11px 8px 11px 2px #00000080;
         margin-top: 40%;
    }


  } 


  @media screen and (min-width:1200px) and (max-width:1440px){

    .photos{
        height: 85vh;
    }

    .photosItem{
        margin-bottom: 55px;

    }

    .photosItemMobie{
        display: none;
       }


       .photosItemTablets{
        display: none;
    }


  }

  
    @media screen and (min-height:1200px){

        .photos{
            height: 55vh;
        }
        .photosItem{
            margin-bottom: 35px;
    
        }
        
    
      }
    
    
     */



