.header{
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to bottom, #1c0c5b, #221264, #29186d, #2f1e77, #352480, #402c88, #4a3391, #543b99, #6447a2, #7352ac, #825fb5, #916bbf);

}

.mainTitle{
    font-size: 2.2em;
    margin-bottom: 25px;
    font-weight: normal;
    color: white;
    letter-spacing: 5px;
    direction: rtl;
    text-align: right;
}

.headerContainer{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
}

.headerItems {
    width:39%;
    flex-direction: column;
    color: white;
    position: relative;
    margin-top: 80px;
    direction: rtl;
}


.imgContainer{
    width: 60%;
}


.headerImg{
    width: 400px;
    height: auto;
}

.mainText{
    font-size: 1.125em;
    line-height: 30px;
    font-weight: 300;

}
.headerButton{
    display: flex;
    border: 2px solid white;
    border-radius: 50px;
    padding: 12px 40px;
    background-color: #6447a2;
    color: white;
    cursor: pointer;
    transition-duration: 0.2s;
    font-weight: bold;
    margin-top: 35px;
    direction: rtl;
    font-size: 13px;
}

.headerButton:hover{

    background-color: white;
    color: #916bbf ;
}


.animate__animated.animate__bounceInLeft{
    --animate-duration: 1s;
}
  


@media screen and (max-width:768px) {
    
    .headerItems{
        width:55%;

    }
    .imgContainer{
        width:45%;
    }

    
}

@media screen and (max-width:700px) {
    

    .imgContainer{
        width:45%;
    }
    .headerImg{
        width: 350px;

    }
    .mainTitle{
        font-size: 1.75em;
    }
    .mainText{
        font-size: 1.125em;
    }
    
    .headerButton{
        padding: 12px 35px;
    }

    
}

@media screen and (max-width:630px) {


    .headerItems{
        margin-top: 40px;

    }
    
    .headerImg{
        width: 300px;

    }
    .mainTitle{
        font-size: 1.5625em;
    }
    .mainText{
        font-size: 1em;
    }
    
    .headerButton{
        padding: 10px 30px;
        margin-top: 20px;
    }
    
}
@media screen and (max-width:530px) {

    .headerItems{
        margin-top: 25px;

    }
    
    .headerImg{
        width: 260px;

    }
    .mainTitle{
        margin-bottom: 10px;
    }
    .mainText{
        font-size: 15px;
    }
    
    .headerButton{
        padding: 8px 25px;
        margin-top: 20px;
        font-size: 12px;
    }
    
}

@media screen and (max-width:480px) {


    .mainTitle{

        font-size: 1.875em;

    }


    .headerContainer{
        flex-direction: column;
    }
    
    .headerItems{
        width: 90%;
    }

    .mainText{
        font-size: 1.125em;
    }

    .headerButton{
        padding: 12px 35px;


    }

    .imgContainer{
        width: 77%;
        direction: rtl;
    }

}

@media screen and (max-width:320px) {
    
    .imgContainer{
        width: 100%;
    }




}










  