.companies{
    display: flex;
    justify-content: center;
    position: relative;

}

.compContainer{
    width: 100%;
    max-width: 1024px;
    padding: 0 15px;

}

.companyItem{
    cursor: pointer;
}

.compTitle{
    position: absolute;
    font-size: 40px;
    color: #3D2C8D;
    font-weight: 400;
    top:15px;
}

.itemsWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:200px 0 180px 0;
    gap:15px;


}

.compImg{
    height: 55px;
}


@media screen and (max-width:1024px) {

.itemsWrapper{
    flex-wrap: wrap;
    justify-content: center;
    margin:150px 0 130px 0;
    gap:60px;


}
    
}









