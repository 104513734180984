.about{
    display: flex;
    justify-content: center;
    position: relative;
}

.aboutTitle{
    position: absolute;
    top:15px;
    font-size: 40px;
    font-weight: 400;
    color:  #1C0C5B;

}

.itemsContainer{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    padding: 0 15px;
    gap:30px;
    
}

.aboutItems{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.aboutTitle.animate__animated.animate__fadeIn{
    --animate-duration: 2s;
}

.animate__animated.animate__fadeInLeft
{
    --animate-duration: 2s;
}

.videoContainer{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;
}


.aboutText{
    font-size: 1.0625em;
    line-height: 24px;
    font-weight: 400;
    direction: rtl;
    color: rgb(67, 67, 67);
    margin-bottom: 140px;
}

.aboutButton{
    position: absolute;
    border: 2px solid #916bbf;
    border-radius: 50px;
    padding: 15px 35px;
    background-color: white;
    color: #916bbf;
    bottom:50px;
    cursor: pointer;
    transition-duration: 0.2s;
    font-size: 15px;
    font-weight: bold;
   
}

.videoItem{
    height: 250px;
    width: 450px;
    -webkit-box-shadow: 11px 8px 11px 2px rgba(0,0,0,0.5); 
    box-shadow: 11px 8px 11px 2px #00000080;
}

.aboutButton:hover{

    background-color: #916bbf;
    color: white;
}

@media screen and (max-width:1023px) {
    

    .itemsContainer{

        flex-direction: column-reverse;
        padding: 0 10px;
        gap:0px;


    }
    .videoContainer{
        width: 65%;
        margin-bottom: 40px;
    }

    .videoItem{

        margin-bottom: 10px;

    }

    .aboutItems {
        width: 60%;
    }
    .aboutButton{

        transform: translate(-50%,-50%);
        bottom: 10px;

    }
    
}



@media screen and (max-width:768px) {

    .videoItem{
        height: 200px;
        width: 360px;

    }

    .aboutItems {
        width: 75%;
    }



}


@media screen and (max-width:480px) {


    .videoItem{
        height: 180px;
        width: 320px;

    }

    .aboutItems {
        width: 90%;
    }

    .aboutText{
        font-size: 1em;

    }
    


}

