
.home{
    display: flex;
    flex-direction: column;
}

.languageCointaner{
    width: 100%;
    max-width: 1024px;
    position: relative;
}
.languageWrapper{
    position: absolute;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 0 7px;

}
.israelIcon{
    width: 20px;
    height: 20px;
    margin-top: 5px;
    cursor: pointer;
}
.usaIcon{
    width: 20px;
    height: 20px;
    margin-top: 3px;
    cursor: pointer;
}

.languageWrapper>p:hover{
    color: lightgray;

}


@media screen and (max-width: 768px){

    .languageCointaner{
        z-index: 998;
    }
    .languageWrapper{
        right: 0;
        margin-top: 70px;
    }


}
@media screen and (min-width: 769px) and (max-width:1024px){

    .languageCointaner{
        z-index: 998;

    }
    .languageWrapper{
        left: 3%;
        margin-top: 70px;
    }


}