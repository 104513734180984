.headerEng{
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to bottom, #1c0c5b, #221264, #29186d, #2f1e77, #352480, #402c88, #4a3391, #543b99, #6447a2, #7352ac, #825fb5, #916bbf);
}

.mainTitleEng{
    font-size: 2.1875em;
    margin-bottom: 15px;
    font-weight: normal;
    color: white;
    letter-spacing: 6px;
    
}


.headerContainerEng{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
    
}

.headerItemsEng{
    width: 40%;
    flex-direction: column;
    color: white;
    margin-top: 60px;
    position: relative;

}
.imgContainerEng{
    width: 60%;
}

.headerImgEng{
    width: 400px;
    height: auto;

}

.mainTextEng{
    font-size: 1.25em;
    line-height: 30px;
    font-weight: 300;
    direction: ltr;

 
}

.headerButtonEng{
    display: flex;
    justify-content: flex-start;
    border: 2px solid white;
    border-radius: 50px;
    padding: 15px 40px;
    background-color: #6447a2;
    color: white;
    cursor: pointer;
    transition-duration: 0.2s;
    font-weight: bold;
    margin-top: 30px;
    
}


.headerButtonEng:hover{

    background-color: white;
    color: #916bbf ;
}

@media screen and (max-width:1100px) 
{

    .mainTitleEng{
        font-size: 2em;

    }
    .mainTextEng{
        font-size: 1.125em;
    }
    .headerButtonEng{

        padding: 15px 40px;
    }

    .headerImgEng{
        width: 360px;
        height: auto;
    
    }

  
    
}

@media screen and (max-width:820px) 
{
    .mainTitleEng{
        font-size: 2.1875em;
        margin-bottom: 10px;

    }

    .headerItemsEng{
        margin-top: 35px;
        width:60%;

    }
    .mainTextEng{
        font-size: 1.0625em;
    }
    .headerButtonEng{

        padding: 15px 35px;
        margin-top: 30px;
    }

    .headerImgEng{
        width: 330px;
        height: auto;
    
    }

    
}

@media screen and (max-width:768px) 
{

    .mainTitleEng{
        font-size: 1.875em;

    }

    .headerItemsEng{

        width: 50%;

    }
    .imgContainerEng{
        width: 50%;
    }
    

    .mainTextEng{
        font-size: 1.125em;
    }
    .headerButtonEng{

        padding: 15px 35px;
        margin-top: 25px;

    }

    .headerImgEng{
        width: 350px;
        height: auto;
    
    }

}


@media screen and (max-width:648px) {

    .mainTitleEng{
        font-size: 1.875em;
    }

    .headerItemsEng{

        width: 50%;

    }
  

    .mainTextEng{
        font-size: 1.0625em;
    }
    .headerButtonEng{

        padding: 15px 30px;
        margin-top: 25px;


    }

    .imgContainerEng{
        width: 50%;

    }


    .headerImgEng{
        width: 350px;
        height: auto;
    
    }




}


@media screen and (max-width:621px) {


    .mainTitleEng{
        font-size: 1.75em;

    }

    .headerItemsEng{

        width: 50%;

    }
    .imgContainerEng{
        width: 50%;
    }
    

    .mainTextEng{
        font-size: 1em;
    }
    .headerButtonEng{

        padding: 12px 32px;
        margin-top: 25px;
        font-size: 12px;


    }

    .headerImgEng{
        width: 330px;
        height: auto;
    
    }


}
@media screen and (max-width:586px) {


    .mainTitleEng{
        font-size: 1.75em;

    }

    .headerItemsEng{

        width: 55%;

    }
    .imgContainerEng{
        width: 45%;
    }
    

    .mainTextEng{
        font-size: 0.96875em;
    }
    .headerButtonEng{

        padding: 8px 30px;
        margin-top: 22px;
        font-size: 12px;
    }

    .headerImgEng{
        width: 310px;
        height: auto;
    
    }


}



@media screen and (max-width:480px) {

    
    .headerContainerEng{

        flex-direction: column;
    
    }


    .mainTitleEng{
        font-size: 1.875em;

    }

    .headerItemsEng{

        width: 90%;

    }

    
    .mainTextEng{
        font-size: 1.0625em;
    }

    .headerButtonEng{

        padding: 15px 35px;
        margin-top: 25px;
        margin-bottom: 10px;
        font-size: 12px;

    }


    .headerImgEng{
        width: 300px;
        height: auto;

    }


}


@media screen and (max-width:375px) {

    .headerItemsEng{
        
        width: 100%;
    }


    .headerImgEng{
        width: 300px;
        height: auto;
    
    }



}



@media screen and (max-width:320px) {

    .headerContainerEng{
    
        flex-direction: column;
    
    }
    
    .headerItemsEng{
        
        width: 100%;
    }

    .mainTitleEng{
        font-size: 1.6875em;

    }
    .mainTextEng{
        font-size: 1.0625em;
    }
    .headerButtonEng{

        padding: 12px 30px;

    }
    
    .headerImgEng{
        width: 250px;
        height: auto;
    
    }
    
} 
