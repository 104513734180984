.details{
    display: flex;
    justify-content: center;
    background-color:rgb(21,32,43);
    position: relative;

}


.contactTitle{
    font-size: 40px;
    font-weight: 400;
    color: white;
    position:absolute;
    top:15px;

}

.detailsContainer{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 150px 0 180px 0;
    padding: 5px;
    gap: 30px

  
}

.contactItems{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;


}

.contactForm{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    position: relative;

}

.contactTxt{
    width: 345px;
    height: 160px;
    border-radius: 15px;
    border: 1px solid white;
    line-height: 1.5em;
    text-align: right;
    background-color: transparent;
    color: white;
    padding: 0 5px;


}
.contactTxt::placeholder{
    font-size: 15px;
    color: white;

}


.contactInput{
    height: 40px;
    width: 290px;
    border-radius: 15px;
    border: 1px solid white;
    background-color: transparent;
    text-align: right;
    color: white;
    padding: 0px 10px;
}
.contactInput::placeholder{
    color: white;

}



.contactBtn{
    position: absolute;
    transform: translate(-50%,-50%);
    left:50%;
    bottom:15%;
    border: 1px solid white;
    border-radius: 50px;
    padding: 15px 35px;
    background-color: #6447a2;
    color: white;
    cursor: pointer;
    transition-duration: 0.2s;
    font-weight: 600;

}

.contactBtn:hover{

    background-color: white;
    color: #916bbf ;
}


.contactMsg{
    position: absolute;
    transform: translate(-50%,-50%);
    left:50%;   
    font-size: 25px;
    font-weight: 500;
    white-space: nowrap;
    color: white;
    bottom:4%;

}

.inputWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}




.detailsItems{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap : 30px;
    color: white;
    align-items: flex-end;
    margin-bottom: 15px;
    
    
}

.detailItem{
    display: flex;
    justify-content: center;
    align-items: center;
    direction: rtl;
    
}
.detailIcon{
    font-size: 1.5625em;
}

.detailItem>span{
    font-size: 1em;
    font-weight: 500;
    margin-right: 15px;
    letter-spacing: 0.5px;

}

.contactMsgMobile{
    display: none;

}

@media screen and (max-width:1023px) 
{
    .detailsContainer{
        flex-direction: column;
        width: 360px;
        margin: 120px 0 180px 0;
    }

    .contactForm{
        flex-direction: column-reverse;
    }

    .contactInput{
        width:345px;
    }

    .contactBtn{
        bottom: 10%;
    }
    .contactTxt{
        padding: 0 10px;
    }

    
}

@media screen and (max-width:745px) 
{


    .contactMsg{
        font-size: 22px;
        white-space: nowrap;
        }

    
}
@media screen and (max-width:645px) 
{


    .contactMsg{
        font-size: 18px;
        white-space: nowrap;
        }

    
}

@media screen and (max-width:515px) 
{


    .contactMsg{
     display: none;
        }

        .contactMsgMobile{
            display: block;
            position: absolute;
            transform: translate(-50%,-50%);
            left:50%;   
            font-size: 20px;
            font-weight: 500;
            white-space: nowrap;
            color: white;
            bottom:4%;
        

        }

    
}
@media screen and (max-width:400px) 
{
    .detailsContainer{
        width: 300px ;
    }

    .contactTxt{
        width: 280px;
    }

    .contactInput{
        width:280px;
    }

    .contactMsgMobile{
            display: block;
            position: absolute;
            transform: translate(-50%,-50%);
            left:50%;   
            font-size: 18px;
            font-weight: 500;
            white-space: nowrap;
            color: white;
            bottom:4%;
        

        }

    
}



  
